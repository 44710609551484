/**
 * @file ReqApplicationForm.js
 * @description Form to collect user job application details and submit to Google Sheets.
 *              Implements bot prevention using honeypot fields, accessibility enhancements, 
 *              and dynamic ID generation for form fields. Includes success/error handling.
 * @module ReqApplicationForm
 */

import React, { useState, useMemo, useCallback} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import "../css/ReqApplicationForm.css";
import { nursingSpecialtyOptions } from "../utils/constants";


export default function ReqApplicationForm() {
  const location = useLocation();
  const navigate = useNavigate();

  // Pre-fill requisition number from state
  const requisitionNumber = location.state?.requisitionNumber || "N/A";

  // State variables for form data, errors, and submission status
  const [formData, setFormData] = useState({
    requisitionNumber,
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    canWorkInCanada: "",
    nsLicence: "",
    nursingSpecialties: [],
    consent: "",
    honeypot: "", // Honeypot field for bot detection
    formStartTime: Date.now(), // Start Time Field
  });

  const [errors, setErrors] = useState({});
  const [submitStatus, setSubmitStatus] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Options for nursing specialties
  const memoizedSpecialtyOptions = useMemo(() => nursingSpecialtyOptions, []);

  /**
   * @function handleChange
   * @description Handles input field changes and updates form state.
   */
  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  }, []);

  /**
   * @function handleSpecialtyChange
   * @description Updates nursing specialties in the form data.
   */
  const handleSpecialtyChange = useCallback((selectedOptions) => {
    setFormData((prev) => ({
      ...prev,
      nursingSpecialties: selectedOptions.map((opt) => opt.value),
    }));
  }, []);

  /**
   * @function resetForm
   * @description Resets the form to its initial state.
   */
  const resetForm = useCallback(() => {
    setFormData({
      requisitionNumber,
      firstName: "",
      lastName: "",
      email: "",
      mobile: "",
      canWorkInCanada: "",
      nsLicence: "",
      nursingSpecialties: [],
      consent: "",
      honeypot: "",
      formStartTime: Date.now(),
    });
  }, [requisitionNumber]);
  

  /**
   * @function validate
   * @description Validates the form inputs before submission.
   * @returns {Object} An object containing validation errors.
   */
  const validate = () => {
    const newErrors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;

    if (!formData.firstName) newErrors.firstName = "First Name is required.";
    if (!formData.lastName) newErrors.lastName = "Last Name is required.";
    if (!formData.email || !emailRegex.test(formData.email))
      newErrors.email = "Enter a valid email address in the format user@example.com.";
    if (!formData.mobile || !phoneRegex.test(formData.mobile))
      newErrors.mobile =
        "Enter a valid phone number in the format (XXX) XXX-XXXX, XXX-XXX-XXXX, or XXX.XXX.XXXX.";
    if (!formData.canWorkInCanada) newErrors.canWorkInCanada = "Select eligibility.";
    if (!formData.nsLicence) newErrors.nsLicence = "NS Licence is required.";
    if (formData.nursingSpecialties.length === 0)
      newErrors.nursingSpecialties = "Select at least one specialty.";
    if (!formData.consent) newErrors.consent = "Consent is required.";

    return newErrors;
  };

  /**
   * @function handleSubmit
   * @description Submits the form after validation and prevents bot activity.
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check honeypot field for bot submissions
    if (formData.honeypot) {
      console.warn("Honeypot field detected a bot submission.");
      return;
    }

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    try {
      setIsSubmitting(true);
      setSubmitStatus("Submitting...");

      // Exclude honeypot and start time fields from submission data
      const { honeypot, formStartTime, ...submissionData } = formData;

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api"}/submit-job-application`,
        submissionData
      );

      if (response.status === 200) {
        setSubmitStatus("Application submitted successfully!");
        resetForm();
        setFormData((prev) => ({
          ...prev,
          requisitionNumber,
          firstName: "",
          lastName: "",
          email: "",
          mobile: "",
          canWorkInCanada: "",
          nsLicence: "",
          nursingSpecialties: [],
          consent: "",
          honeypot: "",
          formStartTime: Date.now(),
        }));
          // After showing success message for 3 seconds, navigate to Nursing Contracts page
          setTimeout(() => {
            navigate("/nursing-contracts");
          }, 3000);
      } else {
        setSubmitStatus("Submission failed. Please try again.");
      }
    } catch (error) {
      console.error("Submission Error:", error.response?.data || error.message);
      setSubmitStatus(error.response?.data?.error || "An error occurred. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="req-application-form">
      <h1>Job Application</h1>

      {/* Display all errors */}
      {Object.keys(errors).length > 0 && (
        <div className="error-summary">
          <h2>Please address the following issues:</h2>
          <ul>
            {Object.values(errors).map((error, index) => (
              <li key={`error-${index}`} className="error">
                {error}
              </li>
            ))}
          </ul>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <label htmlFor="reqNum">Requisition Number:</label>
        <input
          id={`reqNum-${formData.requisitionNumber}`}
          name="requisitionNumber"
          value={formData.requisitionNumber}
          readOnly
          aria-label="Requisition Number"
        />

        {/* Honeypot Field */}
        <input
          type="text"
          name="honeypot"
          value={formData.honeypot}
          onChange={handleChange}
          style={{ display: "none" }}
          tabIndex="-1"
          autoComplete="off"
        />

        <label htmlFor="firstName">First Name:</label>
        <input
          id="firstName"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          autoComplete="given-name"
          aria-label="First Name"
          aria-describedby="firstNameError"
        />
        {errors.firstName && <p id="firstNameError" className="error">{errors.firstName}</p>}

        <label htmlFor="lastName">Last Name:</label>
        <input
          id="lastName"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          autoComplete="family-name"
          aria-label="Last Name"
          aria-describedby="lastNameError"
        />
        {errors.lastName && <p id="lastNameError" className="error">{errors.lastName}</p>}

        <label htmlFor="email">Email:</label>
        <input
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          autoComplete="email"
          aria-label="Email"
          aria-describedby="emailError"
        />
        {errors.email && <p id="emailError" className="error">{errors.email}</p>}

        <label htmlFor="mobile">Mobile:</label>
        <input
          id="mobile"
          name="mobile"
          value={formData.mobile}
          onChange={handleChange}
          autoComplete="tel"
          aria-label="Mobile Number"
          aria-describedby="mobileError"
        />
        {errors.mobile && <p id="mobileError" className="error">{errors.mobile}</p>}

        <label htmlFor="canWorkInCanada">Eligibility to Work in Canada:</label>
        <select
          id="canWorkInCanada"
          name="canWorkInCanada"
          value={formData.canWorkInCanada}
          onChange={handleChange}
          aria-label="Eligibility to Work in Canada"
        >
          <option value="" disabled>
            Select
          </option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
        {errors.canWorkInCanada && <p className="error">{errors.canWorkInCanada}</p>}

        <label htmlFor="nsLicence">Have a valid NS Nursing Licence:</label>
        <select
          id="nsLicence"
          name="nsLicence"
          value={formData.nsLicence}
          onChange={handleChange}
          aria-label="NS Nursing Licence"
        >
          <option value="" disabled>
            Select
          </option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
        {errors.nsLicence && <p className="error">{errors.nsLicence}</p>}

        <label htmlFor="nursingSpecialties">Nursing Specialties:</label>
        <Select
          isMulti
          id="nursingSpecialties"
          options={memoizedSpecialtyOptions}
          className="nursing-specialty-dropdown"
          onChange={handleSpecialtyChange}
          aria-label="Nursing Specialties"
        />
        {errors.nursingSpecialties && <p className="error">{errors.nursingSpecialties}</p>}

        <label htmlFor="consent">Consent for Data Sharing:</label>
        <select
          id="consent"
          name="consent"
          value={formData.consent}
          onChange={handleChange}
          aria-label="Consent for Data Sharing"
        >
          <option value="" disabled>
            Select
          </option>
          <option value="Yes">Yes</option>
          <option value="No">No</option>
        </select>
        {errors.consent && <p className="error">{errors.consent}</p>}

        <p className="warning-text">
        JNN Professional Services Inc. collects personal data from job applicants solely for recruitment and employment purposes. This data is used to evaluate qualifications, verify information, and facilitate the hiring process. We are committed to handling personal information responsibly and in compliance with applicable Canadian privacy laws, including the Personal Information Protection and Electronic Documents Act (PIPEDA). By submitting an application, applicants consent to the collection, use, and limited sharing of their data with relevant third parties involved in the recruitment process. Data will be retained only as long as necessary and securely disposed of thereafter.
        </p>

        <button
          id="submitButton"
          type="submit"
          disabled={isSubmitting}
          aria-label="Submit Application"
        >
          {isSubmitting ? "Submitting..." : "Submit Application"}
        </button>
        {submitStatus && <p className="status">{submitStatus}</p>}
      </form>
    </div>
  );
}
