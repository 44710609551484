/**
 * @file ContractDescription.js
 * @description Dynamic page for displaying detailed contract information, including job description and an Apply button.
 * @module ContractDescription
 */

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "../css/ContractDescription.css";

export default function ContractDescription() {
  const location = useLocation(); // Passed state from NursingContracts.js
  const navigate = useNavigate();
  const [jobDescription, setJobDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const contract = location.state?.opportunity || {};
  const backendUrl = `${
    process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api"
  }`;

  // Helper function for formatting dates to "dd mmm yyyy"
  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  // Fetch Job Description
  useEffect(() => {
    if (contract[1]) {
      const fetchJobDescription = async () => {
        setLoading(true);
        try {
          const sheetRange = "Table1!A2:H"; // Include Job Description column
          const response = await axios.get(
            `${backendUrl}/nursing-contracts?range=${sheetRange}`
          );

          const rows = response.data;

          // Match requisition number (column B)
          const matchedRow = rows.find((row) => row[1] === contract[1]);
          if (matchedRow) {
            const description = matchedRow[7]; // H column
            setJobDescription(description ? description.replace(/\\n/g, "\n") : "No description provided.");
          } else {
            setErrorMessage("Job description not found.");
          }
        } catch (error) {
          console.error("Error fetching job description:", error);
          setErrorMessage("Unable to retrieve job description. Please try again.");
        }
        setLoading(false);
      };

      fetchJobDescription();
    }
  }, [contract, backendUrl]);

  const handleApply = () => {
    navigate("/req-application-form", { state: { requisitionNumber: contract[1] } });
  };

  return (
    <div className="page-container">
      <h1>Contract Details</h1>
      {contract[1] ? (
        <div className="contract-details">
          <p>
            <strong>Requisition Number:</strong> {contract[1]}
          </p>
          <p>
            <strong>Posted:</strong> {formatDate(contract[0])}
          </p>
          <p>
            <strong>Role:</strong> {contract[2]}
          </p>
          <p>
            <strong>Start Date:</strong> {formatDate(contract[4])}
          </p>
          <p>
            <strong>End Date:</strong> {formatDate(contract[5])}
          </p>
          <p>
            <strong>Location:</strong> {contract[6]}
          </p>

          <div className="job-description">
            <h2>Job Description</h2>
            {loading ? (
              <p>Loading job description...</p>
            ) : errorMessage ? (
              <p className="error">{errorMessage}</p>
            ) : (
              <p>{jobDescription}</p>
            )}
          </div>

          <button onClick={handleApply} className="apply-button">
            I'm interested!
          </button>
        </div>
      ) : (
        <p className="error">No contract details available. Please return to the opportunities page.</p>
      )}
    </div>
  );
}
