/**
 * @file constants.js
 * @description Contains reusable constant values used throughout the application. 
 *              Specifically, defines options for nursing specialties in the job application form.
 * @module constants
 * @exports nursingSpecialtyOptions - Array of nursing specialties for dropdown menus and multi-select inputs.
 */

export const nursingSpecialtyOptions = [
    { value: "NA", label: "NA" },
    { value: "Cardiac", label: "Cardiac" },
    { value: "Clinical Nurse Specialist", label: "Clinical Nurse Specialist" },
    { value: "Critical Care", label: "Critical Care" },
    { value: "Dialysis/Nephrology", label: "Dialysis/Nephrology" },
    { value: "ER", label: "ER" },
    { value: "General Medicine", label: "General Medicine" },
    { value: "Geriatric", label: "Geriatric" },
    { value: "ICU", label: "ICU" },
    { value: "Med-Surg", label: "Med-Surg" },
    { value: "Oncology", label: "Oncology" },
    { value: "Palliative", label: "Palliative" },
    { value: "Pediatric", label: "Pediatric" },
  ];
  