/**
 * @file NursingContracts.js
 * @description React component for displaying Travel Nurse opportunities by fetching data 
 *              from the backend, sorting, and presenting it in a user-friendly way.
 * @module NursingContracts
 */

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // For navigation
import "../css/NursingContracts.css";
import SortIcon from "../assets/sort-vertical.svg"; // Sorting icon

export default function NursingContracts() {
  const [opportunities, setOpportunities] = useState([]);
  const [sortedOpportunities, setSortedOpportunities] = useState([]);
  const [sortOrder, setSortOrder] = useState("asc");
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const backendUrl = `${
    process.env.REACT_APP_API_BASE_URL || "http://localhost:8080/api"
  }/nursing-contracts`;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(backendUrl);
        const filteredRows = response.data.filter((row) => row[3] === "Open"); // Only include Open requisitions

        setOpportunities(filteredRows);
        setSortedOpportunities(filteredRows);
        setErrorMessage("");
      } catch (error) {
        console.error("Error fetching data from backend:", error);
        setErrorMessage("Unable to fetch opportunities. Please try again later.");
      }
      setLoading(false);
    };

    fetchData();
  }, [backendUrl]);

  const handleSort = () => {
    const sorted = [...sortedOpportunities].sort((a, b) => {
      const dateA = new Date(a[0] || null);
      const dateB = new Date(b[0] || null);
      return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
    });
    setSortedOpportunities(sorted);
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  const handleRequisitionClick = (opportunity) => {
    navigate("/contract-description", { state: { opportunity } });
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  return (
    <div className="page-container">
      <h1>Travel Nurse Opportunities</h1>
      {loading ? (
        <p>Loading opportunities...</p>
      ) : errorMessage ? (
        <p>{errorMessage}</p>
      ) : sortedOpportunities.length > 0 ? (
        <div>
          <div className="sort-header">
            <button onClick={handleSort} className="sort-button">
              Posting Date
              <img src={SortIcon} alt="Sort" className="sort-icon" />
            </button>
          </div>
          {sortedOpportunities.map((opportunity, index) => (
            <div
              key={index}
              className="opportunity-card"
              onClick={() => handleRequisitionClick(opportunity)}
              onTouchStart={(e) => e.currentTarget.classList.add("active")}
              onTouchEnd={(e) => e.currentTarget.classList.remove("active")}
              onMouseEnter={(e) => e.currentTarget.classList.add("hover")}
              onMouseLeave={(e) => e.currentTarget.classList.remove("hover")}
            >
              <p>
                <strong>Posted:</strong> {formatDate(opportunity[0]) || "N/A"};{" "}
                <strong>Requisition Number:</strong>{" "}
                <span
                  className="requisition-link"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRequisitionClick(opportunity);
                  }}
                >
                  {opportunity[1] || "N/A"}
                </span>
              </p>
              <p>
                <strong>Role:</strong> {opportunity[2] || "N/A"}{" "}
                <strong>Location:</strong> {opportunity[6] || "N/A"}
              </p>
              <p>
                <strong>Start Date:</strong> {formatDate(opportunity[4])}{" "}
                <strong>End Date:</strong> {formatDate(opportunity[5])}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <p>No open opportunities available at the moment.</p>
      )}
    </div>
  );
}
