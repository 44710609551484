import './css/App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import React from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Services from './pages/Services';
import About from './pages/AboutUs';
import Contact from './pages/ContactUs';
import NursingContracts from './pages/NursingContracts';
import Account from './pages/Account';
import ContractDescription from "./pages/ContractDescription";
import ReqApplicationForm from "./components/ReqApplicationForm";

export default function App() {
  return (
    <div className="app-container">
      <BrowserRouter>
        <Navbar />
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/services" element={<Services />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/nursing-contracts" element={<NursingContracts />} />
            <Route path="/account" element={<Account />} />
            <Route path="/contract-description" element={<ContractDescription />} />
            <Route path="/req-application-form" element={<ReqApplicationForm />} />
            {/* Catch-all route */}
            <Route path="*" element={<h1>Page Not Found</h1>} />
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}
